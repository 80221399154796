import axios from "./axios.js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { Card } from "./Card";
import Footer from "./Footer";
import { Container } from "@mui/system";
import PrivacyPolicy from "./PrivacyPolicy/index.jsx";
import LegalInfo from "./LegalInfo/index.jsx";

export const Shop = () => {
  const [cards, setCards] = useState([]);

  const [oneBouquet, setOneBouquet] = useState("");
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      async function fetchOneBouquet() {
        try {
          const resBouquet = await axios.get(`/shop/${params.id}`);

          setOneBouquet(resBouquet.data);
        } catch (error) {
          console.error(error);
          alert("Ошибка при загрузке букетов с сервера: " + error);
        }
      }
      fetchOneBouquet();
    }

    try {
      async function fetchAvailableBouquets() {
        const resCards = await axios.get("/shop");

        setCards(resCards.data.reverse());
      }

      fetchAvailableBouquets();
    } catch (error) {
      console.error(error);
      alert("Ошибка при загрузке букетов с сервера: " + error);
    }
  }, [params.id]);

  return oneBouquet ? (
    <>
      <Container>
        <Grid
          container
          sx={{
            margin: "5px 0 8px 0",
            justifyContent: "space-between",
            gap: "7px",
          }}
        >
          <Grid item xs>
            {oneBouquet.map((bouquet) => {
              return (
                <Card
                  key={bouquet._id}
                  bouquetId={bouquet._id}
                  imageUrl={bouquet.imageUrl}
                  size={bouquet.size}
                  price={bouquet.price}
                  isSold={bouquet.isSold}
                  flowers={bouquet.flowers}
                  {...bouquet}
                />
              );
            })}
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", textAlign: "center", margin: "15px auto" }}>
          <Button variant="outlined" href="https://vazaflower.com">
            Повернутись на головну
          </Button>
        </Box>
      </Container>
      <Footer />
    </>
  ) : (
    <>
      <Container>
        <Grid container spacing={2}>
          {cards.map((card) => {
            return (
              <Grid key={card._id} item xs>
                <Card
                  bouquetId={card._id}
                  imageUrl={card.imageUrl}
                  flowers={card.flowers}
                  size={card.size}
                  price={card.price}
                  isSold={card.isSold}
                  {...card}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Footer />
      <PrivacyPolicy buttonTitle="Угода щодо обробки персональних даних, порядку оплати, доставки та повернення" />
      <LegalInfo />
    </>
  );
};
