import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";

const documentStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  color: "#fff",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const pStype = {
  textAlign: "justify",
  mt: "1rem",
  mb: "1.5rem",
  fontStyle: "bold",
};

export default function PrivacyPolicy() {
  const [show, setShow] = useState(false);
  const showInfo = () => setShow(true);
  const hideInfo = () => setShow(false);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", m: "10px auto" }}>
        <Button onClick={showInfo}>Юридична інформація</Button>
      </Box>
      <Modal
        open={show}
        onClose={hideInfo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={documentStyle}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h3"
            sx={{ mb: "2rem" }}
          >
            Інформація про фізичну особу - підприємця
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Назва
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            ФІЗИЧНА ОСОБА - ПІДПРИЄМЕЦЬ ВІЗІР ДАНИЇЛА МИКОЛАЇВНА
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Реєстраційний номер облікової картки платника податків
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            3564410001
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Місцезнаходження фізичної особи - підприємця
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            Україна, 49020, Дніпропетровська обл., місто Дніпро, вул. Шевченка
            (Новокодацький район), будинок 32А
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Дата та номер запису в Єдиному державному реєстрі юридичних осіб,
            фізичних осіб- підприємців та громадських формувань:
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            26.04.2023, 2010350000000298818
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Відомості, отримані в порядку інформаційної взаємодії між Єдиним
            державним реєстром юридичних осіб, фізичних осіб - підприємців та
            громадських формувань та інформаційними системами державних органів:
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            27.04.2023, ДЕРЖАВНА СЛУЖБА СТАТИСТИКИ УКРАЇНИ, 37507880
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            26.04.2023, 046723084558, ГОЛОВНЕ УПРАВЛІННЯ ДПС У ДНІПРОПЕТРОВСЬКІЙ
            ОБЛАСТІ, ПРАВОБЕРЕЖНА ДПІ (НОВОКОДАЦЬКИЙ Р-Н М.ДНІПРА), 44118658,
            (дані про взяття на облік як платника податків)
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            26.04.2023, 10000002499047, ГОЛОВНЕ УПРАВЛІННЯ ДПС У
            ДНІПРОПЕТРОВСЬКІЙ ОБЛАСТІ, ПРАВОБЕРЕЖНА ДПІ (НОВОКОДАЦЬКИЙ Р-Н
            М.ДНІПРА), 44118658, (дані про взяття на облік як платника єдиного
            внеску)
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Види економічної діяльності:
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            47.76 Роздрібна торгівля квітами, рослинами, насінням, добривами,
            домашніми тваринами та кормами для них у спеціалізованих магазинах
            (основний)
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            47.89 Роздрібна торгівля з лотків і на ринках іншими товарами
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            47.78 Роздрібна торгівля іншими невживаними товарами в
            спеціалізованих магазинах
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            47.62 Роздрібна торгівля газетами та канцелярськими товарами в
            спеціалізованих магазинах
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Інформація для здійснення зв'язку:
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            +38(063)-411-32-99
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            vazaflowersdp@gmail.com
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Номер, дата та час формування виписки:
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            226020468137, 27.04.2023 19:02:04
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Прізвища, імена, по батькові осіб, які мають право вчиняти юридичні
            дії від імені фізичної особи - підприємця без довіреності, у тому
            числі підписувати договори, дані про наявність обмежень щодо
            представництва від імені фізичної особи - підприємця:
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            Відомості відсутні
          </Typography>

          <Typography id="modal-modal-title" variant="h7" component="h3">
            Запис про припинення підприємницької діяльності фізичної особи -
            підприємця або підприємницьку діяльність фізичної особи - підприємця
            припинено:
          </Typography>

          <Typography id="modal-modal-description" sx={pStype}>
            Відомості відсутні
          </Typography>

          <Box
            sx={{ display: "flex", justifyContent: "center", m: "10px auto" }}
          >
            <Button onClick={hideInfo} variant="contained">
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
