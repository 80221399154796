import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";

const documentStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  color: "#fff",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const pStype = { textAlign: "justify", mt: "1rem", mb: "1.5rem" };

export default function PrivacyPolicy({ buttonTitle, style, size = "large" }) {
  const [show, setShow] = useState(false);
  const showPolicy = () => setShow(true);
  const hidePolicy = () => setShow(false);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", m: "10px auto" }}>
        <Button sx={style} size={size} onClick={showPolicy}>
          {buttonTitle}
        </Button>
      </Box>
      <Modal
        open={show}
        onClose={hidePolicy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={documentStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h">
            Обробка персональних данних
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            Телефонний номер Клієнта та адресата, які отримуються нами при
            оформленні замовлення є конфіденційною інформацією та не можуть бути
            передані третім особам і можуть бути використані для виконання
            замовлення. Номер телефону Клієнта може бути використаний для
            врахування накопичувальної знижки за прораммою лояльності та
            повідомлення Клієнта про персональні та святкові пропозиції. За
            зверненням Клієнта, такі повідомлення можуть бути скасовано.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Процедура розгляду претензій
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            Якщо товар був доставлений із пошкодженнями або не відповідає
            замовленню, покупець зобов'язаний надати фотографії товару та
            упаковки для розгляду скарги. Після підтвердження скарги наша служба
            підтримки запропонує компенсацію, заміну або повернення коштів (за
            згодою сторін).
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Повернення товару
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            Відповідно до Постанови Кабінету Міністрів України №172 від 19
            березня 1994 року, живі квіти та інші швидкопсувні товари не
            підлягають поверненню та обміну. У разі доставки пошкодженого або
            неналежної якості товару покупець має право звернутися до служби
            підтримки протягом 24 годин з моменту отримання для вирішення
            ситуації.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Скасування транзакції та повернення коштів
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            Покупець може скасувати замовлення до його відправлення, звернувшись
            до нашої служби підтримки. Моментом відаправлення вважаеться момент
            оформлення замовлення послуги доставки у кур'єрській службі (Uber,
            Bolt, Uklon, власна кур'єрська служба)
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h">
            Якщо неможливо виконати попереднє замовлення
          </Typography>
          <Typography id="modal-modal-description" sx={pStype}>
            У разі виникнення ситуації, коли Клієнт попередньо оформив
            замовлення на певну дату, на квіти, яких поки нема в наявності, але
            з будь-яких причин замовлення виконати неможливо (неякісна поставка,
            скасування поставки постачальником, відтермінування поставки, тощо),
            Клієнту буде запропоновано альтернативні варіанти за наявних квітів
            у визначеному бюдджеті або поверненню коштів.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", m: "10px auto" }}
          >
            <Button onClick={hidePolicy} variant="contained">
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
